@charset "UTF-8";
.table_height[data-v-15ed46b5] {
  overflow-y: overlay;
  overflow-x: hidden;
  margin-top: 10px;
  scrollbar-width: none;
}
[data-v-15ed46b5]::-webkit-scrollbar {
  width: 0px;
}

/*滚动条里面小方块*/
[data-v-15ed46b5]::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.clicked[data-v-15ed46b5] {
  color: var(--themeColor, #17a2b8) !important;
  font-weight: 600;
}
[data-v-15ed46b5] .el-timeline-item {
  padding-bottom: 10px;
}
[data-v-15ed46b5] .el-timeline-item__wrapper {
  padding-left: 15px;
}
.main[data-v-15ed46b5] {
  border: 1px solid #efefef;
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
}